import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  statusStart,
  statusWaitting,
  adminFinish,
  timeOutTask,
  getAdminTask,
  resetAll,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  getlistTrims,
  acceptImageRequest,
  rejectImageRequest,
  clearMessageTask,
} from './../operations';
import SVG from 'react-inlinesvg';
import DamageView from './components/DamageView';
import AcceptButton from './components/AcceptButton';
import Inspect from './components/Inspect';
import ModalImage from './components/ModalImage';
import ModalWatting from './components/ModalWatting';
import TitleInspect from './components/TitleInspect';
import InfoBar from './components/InfoBar';
import PhotoThumb from './components/PhotoThumb';
import PhotoFull from './components/PhotoFull';
import { logout } from '../../auth/operations';
import $ from 'jquery';
import ModalCheckAdmin from './components/ModalCheckAdmin';
import ModalShowErr from './components/ModalShowErr';
import Modal from './components/Modal';
import ModalImageFull from './components/ModalImageFull';
import FinishButton from './components/FinishButton';
import ModalCheckSameInspection from './components/ModalCheckSameInspection';
import ModalMD from '../../inspections/containers/components/partials/ModalMD';
window.$ = $;

const Process = (props) => {
  const [imgW, setimgW] = useState(0);
  const [imgH, setimgH] = useState(0);
  const [showImgInspect, setshowImgInspect] = useState(false);
  const [cagePo, setcagePo] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [img, setimg] = useState('');
  const [showBtn, setshowBtn] = useState({
    accept: false,
    finish: false,
    reject: false,
  });
  const [showInspectInfo, setshowInspectInfo] = useState(false);
  const [showSvg, setshowSvg] = useState(false);
  const [btnConfirm, setbtnConfirm] = useState({
    title: '',
    name: '',
    des: '',
  });
  const [showWarning, setshowWarning] = useState(false);
  const [messWarning, setmessWarning] = useState('');
  const [showConfirm, setshowConfirm] = useState(false);
  const [waitting, setwaitting] = useState(false);
  let [dataInspect, setdataInspect] = useState(null);
  let [damages, setdamages] = useState([]);
  const [agentDamages, setagentDamages] = useState([]);
  const [rotate, setrotate] = useState(0);
  const [zoom, setzoom] = useState(1);
  const [scale, setscale] = useState(1);
  const [show, setshow] = useState(false);
  const [cageShow, setcageShow] = useState(true);
  const [disableDragging, setdisableDragging] = useState(false);
  const [support, setsupport] = useState({ support: '', index: 0 });
  const [modalImage, setmodalImage] = useState(false);
  const [modalImageFull, setmodalImageFull] = useState(false);
  const [modalErr, setmodalErr] = useState(false);
  const [imgDone, setimgDone] = useState(false);
  const [modalCheckSameInspection, setmodalCheckSameInspection] =
    useState(false);
  const [modalShowTimeOutSession, setmodalShowTimeOutSession] = useState(false);
  const [runOneTime, setrunOneTime] = useState(false);
  let { inspection, auth } = props;
  let { adminTask } = inspection;
  const sameTask = adminTask.sameTask ? adminTask.sameTask : null;
  const sametaskSlot = adminTask.sametaskSlot ? adminTask.sametaskSlot : null;
  let taskSlot = adminTask.taskSlot ? adminTask.taskSlot : null;
  let idTaskSlot = taskSlot && taskSlot._id ? taskSlot._id : null;
  let taskType = taskSlot && taskSlot.type ? taskSlot.type : null;
  let taskInspect = adminTask.task ? adminTask.task : {};
  let inspectionInspect = adminTask.inspection ? adminTask.inspection : {};
  let vehicle = adminTask.vehicle ? adminTask.vehicle : null;
  let cageSvg = adminTask.cage ? adminTask.cage : null;
  const taskSetting =
    adminTask && adminTask.taskSetting ? adminTask.taskSetting : {};
  let listDamages = inspection.listDamages;
  let inspectName =
    taskSlot && taskSlot.inspects ? taskSlot.inspects.name : false;
  let imgSupport =
    taskSlot && taskSlot.options && taskSlot.options.supportView
      ? taskSlot.options.supportView
      : false;
  let unauthorized =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.data
      ? inspection.error.response.data
      : null;
  let options = taskInspect && taskInspect.options ? taskInspect.options : {};
  let error =
    inspection &&
    inspection.error &&
    inspection.error.response &&
    inspection.error.response.status
      ? inspection.error.response.status
      : null;
  const sessionId =
    inspectionInspect && inspectionInspect.sessionID
      ? inspectionInspect.sessionID
      : null;
  const checkTest = sessionId ? sessionId.startsWith('T') : null;
  let themeColor =
    inspectionInspect &&
    inspectionInspect.inspectionThemes &&
    inspectionInspect.inspectionThemes.themeColor
      ? inspectionInspect.inspectionThemes.themeColor
      : '';

  useEffect(() => {
    setshow(false);
    if (!taskSlot) {
      props.getAdminTask(props.taskSlotID);
    }
    // eslint-disable-next-line
    return () => {
      props.resetAll();
    };
  }, []);
  useEffect(() => {
    loadImage();
    if (taskType === 'QC' && taskInspect.statusImg !== 'ACCEPTED') {
      setshowBtn({
        accept: true,
        reject: true,
        finish: false,
      });
      setshowInspectInfo(false);
    } else {
      setshowBtn({
        accept: false,
        reject: false,
        finish: true,
      });
      setshowInspectInfo(true);
    }
    if (adminTask.message === 'Success!') {
      if (
        [
          'vin',
          'qc_plate',
          'qc_odom',
          'qc_interior',
          'qc_tire',
          'qc_glass',
        ].includes(inspectName)
      ) {
        setshowInspectInfo(true);
        setshowBtn({
          accept: false,
          reject: false,
          finish: true,
        });
        props.clearMessageTask();
        setwaitting(false);
        if (['FINISHED', 'REJECTED'].includes(adminTask.statusTask)) {
          setwaitting(false);
          setshowBtn({
            accept: false,
            reject: false,
            finish: false,
          });
          setshowInspectInfo(false);
          props.resetAll();
          props.hideModal();
        }
      } else {
        setwaitting(false);
        setshowBtn({
          accept: false,
          reject: false,
          finish: false,
        });
        setshowInspectInfo(false);
        props.resetAll();
        props.hideModal();
      }
    } else if (unauthorized === 'Unauthorized' || error === 400) {
      setmodalErr(true);
    }
    return () => {};
    // eslint-disable-next-line
  }, [error, adminTask.message, unauthorized, taskSlot, adminTask.statusTask]);
  let resetError = () => {
    props.resetAll();
    props.hideModal();
  };
  let calShowCage = () => {
    loadDamages();
    if (
      options &&
      options.cageInfo &&
      parseInt(options.cageInfo.cage.width, 10) > 0
    ) {
      loadSetCage();
    } else {
      loadSvg();
    }
  };
  let onZoomChange = (zoom) => {
    setzoom(zoom);
  };
  let acceptImage = () => {
    let data = {};
    setshowConfirm(false);
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    if (['04', '05', '07', '08'].includes(taskSlot.photoCode)) {
      data['message'] = btnConfirm.name;
      data['cageInfo'] = {
        original_image: {
          width: imgW,
          height: imgH,
        },
        actual_image: {
          width: offsetWidth,
          height: offsetHeight,
        },
        cage: {
          width: cagePo.width,
          height: cagePo.height,
          x: cagePo.x,
          y: cagePo.y,
          cellSize: cagePo.cellSize,
          columns: cagePo.columns,
          rows: cagePo.rows,
        },
        cageSvg: cageSvg,
      };
    } else {
      data['message'] = btnConfirm.name;
    }
    setwaitting(true);
    props.acceptImageRequest(data, idTaskSlot);
  };
  let rejectImage = () => {
    const data = {};
    data['message'] = btnConfirm.name;
    setwaitting(true);
    setshowConfirm(false);
    props.rejectImageRequest(data, idTaskSlot);
  };
  //FinishImage
  let finishImage = () => {
    const tableDamage =
      inspectionInspect &&
      inspectionInspect.options &&
      inspectionInspect.options.damage_table
        ? inspectionInspect.options.damage_table
        : null;
    if (!adminTask) return false;
    $('.btn-reset').trigger('click');
    setshowConfirm(false);
    let data = { inspectData: dataInspect ? { ...dataInspect } : null };
    if (agentDamages && agentDamages.length > 0) {
      let edit_damages = [];
      for (let i = 0; i < agentDamages.length; i++) {
        if (!agentDamages[i].damage_name) {
          setmessWarning('Please Select Component Or Damage');
          setwaitting(false);
          setshowWarning(true);
          return;
        }
        if (
          ['reject', 'edit', 'revert'].includes(agentDamages[i].admin_response)
        ) {
          edit_damages.push({
            ...agentDamages[i],
            label: agentDamages[i].des || agentDamages[i].description,
          });
        }
      }
      data['edit_damages'] = edit_damages;
    }
    if (damages && damages.length > 0) {
      let inspectDamages = [];
      for (let i = 0; i < damages.length; i++) {
        if (damages[i].component && damages[i].damage_name) {
          inspectDamages.push({
            original_image: {
              width: imgW,
              height: imgH,
            },
            ...damages[i],
            label: damages[i].des || damages[i].description || damages[i].label,
            description:
              tableDamage === 'autograde' && damages[i].condition
                ? damages[i].condition
                : damages[i].description
                ? damages[i].description
                : '',
          });
        } else {
          setmessWarning('Please Select Component Or Damage');
          setwaitting(false);
          setshowWarning(true);
          return;
        }
      }
      data['detected_damages'] = inspectDamages;
    }
    props.adminFinish(data, idTaskSlot);
    setwaitting(true);
  };
  //
  let setScaleDown = () => {
    setdisableDragging(true);
    setscale(4);
  };
  let setScaleUp = () => {
    if (taskSlot.photoCode === '01') {
      setdisableDragging(false);
    } else {
      $('.btn-reset').trigger('click');
      setdisableDragging(false);
      setscale(1);
    }
  };
  // RND
  let resizeTop = (width, height, x, y) => {
    let svg = document.getElementsByClassName('svg svg');
    let svgWidth = parseInt(svg[0].getAttribute('width'), 10);
    let ratioCell = parseInt(width, 10) / svgWidth;
    if (x && y) {
      setcagePo({
        x: x,
        y: y,
        width: parseInt(width, 10),
        height: parseInt(height, 10),
        cellSize: Math.ceil(20 * ratioCell),
        columns: cagePo.columns,
        rows: cagePo.rows,
      });
    } else {
      setcagePo({
        ...cagePo,
        cellSize: Math.ceil(20 * ratioCell),
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      });
    }
  };
  const resizeXY = (cagePos) => {
    setcagePo({ ...cagePo, x: cagePos.x, y: cagePos.y });
  };
  const resizeXYDamage = (index, x, y) => {
    agentDamages[index].x = x - 21;
    agentDamages[index].y = y - 21;
    agentDamages[index].admin_response = 'edit';
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    agentDamages[index].width = Math.floor(offsetWidth);
    agentDamages[index].height = Math.floor(offsetHeight);
    agentDamages[index].changePosition = true;
    setagentDamages([...agentDamages]);
  };
  //Damges
  let addDamages = (damages) => {
    setdamages([...damages]);
  };
  let updateDamage = (damages) => {
    setdamages([...damages]);
  };
  let deleteDamage = (damages) => {
    setdamages([...damages]);
  };
  let updateDamagesAgent = (damages) => {
    setagentDamages([...damages]);
  };
  //SVG LOAD
  let OnOfSvg = (keyName) => {
    if (keyName === 'q' && cageShow === true) {
      svgOff();
      setcageShow(!cageShow);
    } else if (keyName === 'q' && cageShow === false) {
      svgDefault();
      setcageShow(!cageShow);
    }
  };
  let svgDefault = () => {
    const pathId = $('path');
    let arrPathId = [];
    //$('#cell').css('opacity', 0.3);
    $('svg image').css('opacity', 0.7);
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0.5);
      }
    }
  };
  let svgOff = () => {
    const pathId = $('path');
    //$('#cell').css('opacity', 0);
    $('svg image').css('opacity', 0);
    let arrPathId = [];
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0);
      }
    }
  };
  let loadImage = () => {
    let imgFull = (taskInspect && taskInspect.image_cap) || false;
    const imgLoading = new Image();
    imgLoading.src = imgFull;
    setimg(`${imgFull}?v=${Date.now()}`);
    if (imgFull) {
      imgLoading.onload = () => {
        setimgW(imgLoading.width);
        setimgH(imgLoading.height);
        setshowImgInspect(true);
        setimgDone(true);
      };
    }
  };
  let loadSvg = () => {
    let svg = document.getElementsByClassName('svg cage');
    if (svg) {
      let svgWidth = svg[0].getAttribute('width');
      let svgHeight = svg[0].getAttribute('height');
      const cellSize = 20;
      const columns = Math.floor(svgWidth / cellSize);
      const rows = Math.floor(svgHeight / cellSize);
      if (svgWidth > 1000) {
        svgWidth = svgWidth / 2;
        svgHeight = svgHeight / 2;
      }
      if (svgWidth > 2000) {
        svgWidth = svgWidth / 3;
        svgHeight = svgHeight / 3;
      }
      const rnd = $('.rnd-cage');
      rnd.css('width', svgWidth);
      rnd.css('height', svgHeight);
      setcagePo({
        x: 0,
        y: 0,
        width: svgWidth,
        height: svgHeight,
        cellSize: cellSize,
        columns: columns,
        rows: rows,
      });
    }
  };
  let loadSetCage = () => {
    const offsetWidth = $('.App-zoom').width();

    const actualImage =
      options && options.cageInfo && options.cageInfo.actual_image.width
        ? options.cageInfo.actual_image.width
        : 0;
    let ratio = 0;
    let cageW =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.width
        ? parseInt(options.cageInfo.cage.width, 10)
        : 0;
    let cageH =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.height
        ? parseInt(options.cageInfo.cage.height, 10)
        : 0;
    let cageX =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.x
        ? parseInt(options.cageInfo.cage.x, 10)
        : 0;
    let cageY =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.y
        ? parseInt(options.cageInfo.cage.y, 10)
        : 0;
    let cellSize =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.cellSize
        ? parseInt(options.cageInfo.cage.cellSize, 10)
        : 0;
    const columns =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.columns
        ? parseInt(options.cageInfo.cage.columns, 10)
        : 0;
    const rows =
      options &&
      options.cageInfo &&
      options.cageInfo.cage &&
      options.cageInfo.cage.rows
        ? parseInt(options.cageInfo.cage.rows, 10)
        : 0;
    if (offsetWidth < actualImage) {
      ratio = actualImage / offsetWidth;
      cageW = cageW / ratio;
      cageH = cageH / ratio;
      cageX = cageX / ratio;
      cageY = cageY / ratio;
      cellSize = cellSize / ratio;
    } else {
      ratio = offsetWidth / actualImage;
      cageW = cageW * ratio;
      cageH = cageH * ratio;
      cageX = cageX * ratio;
      cageY = cageY * ratio;
      cellSize = cellSize * ratio;
    }
    setcagePo({
      x: cageX,
      y: cageY,
      width: cageW,
      height: cageH,
      columns: columns,
      cellSize: cellSize,
      rows: rows,
    });
  };
  let loadDamages = () => {
    let finalDamagesAgent = [];
    let damagesAgent =
      taskSlot && taskSlot.inspectData && taskSlot.inspectData.detected_damages
        ? taskSlot.inspectData.detected_damages
        : [];

    if (damagesAgent.length > 0) {
      for (let i = 0; i < damagesAgent.length; i++) {
        const item = damagesAgent[i];
        if (item.status === 'repaired') {
          continue;
        }
        if (item.admin_response !== 'reject') {
          let offsetWidth = $('.App-zoom').width();
          let offsetHeight = $('.App-zoom').height();
          const actualImageW =
            item.actual_image && item.actual_image.width
              ? item.actual_image.width
              : 0;
          const actualImageH =
            item.actual_image && item.actual_image.width
              ? item.actual_image.height
              : 0;
          let locaX =
            item.damage_location && item.damage_location.x
              ? item.damage_location.x
              : 0;
          let locaY =
            item.damage_location && item.damage_location.y
              ? item.damage_location.y
              : 0;
          let newLocaX = 0;
          let newLocaY = 0;
          const ratioX = parseFloat(
            Number(offsetWidth / actualImageW).toFixed(4),
          );
          const ratioY = parseFloat(
            Number(offsetHeight / actualImageH).toFixed(4),
          );
          newLocaX = parseFloat(Number(locaX * ratioX).toFixed(4));
          newLocaY = parseFloat(Number(locaY * ratioY).toFixed(4));
          finalDamagesAgent.push({
            ...damagesAgent[i],
            x: newLocaX - 10.5,
            y: newLocaY - 10.5,
            admin_response: false,
          });
        }
      }
      setagentDamages([...finalDamagesAgent]);
    }
  };
  const showModalBtn = (title, name, des) => {
    setshowConfirm(true);
    setbtnConfirm({
      title: title,
      name: name,
      des: des,
    });
  };
  let onModalImage = (item, index) => {
    setsupport({ support: item, index: index });
    setmodalImage(true);
  };
  let offModalImage = () => {
    setsupport({ support: '', index: 0 });
    setmodalImage(false);
  };
  return (
    <div className="InspectionBoard">
      <div
        className="Actions"
        style={
          checkTest
            ? { background: '#ffc107' }
            : { background: `${themeColor}` }
        }
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
            {/* Check Photo Full and Thumb */}
            <div className="VehiclePhoto">
              {!showImgInspect ? (
                <PhotoThumb />
              ) : (
                <PhotoFull
                  taskType={taskType}
                  disableDragging={disableDragging}
                  taskInspect={taskInspect}
                  taskSlot={taskSlot}
                  inspectionInspect={inspectionInspect}
                  img={img}
                  imgDone={imgDone}
                  vehicle={vehicle}
                  svg={cageSvg}
                  cagePo={cagePo}
                  damages={damages}
                  setshowSvg={() => setshowSvg(true)}
                  showSvg={showSvg}
                  rotate={rotate}
                  zoom={zoom}
                  scale={scale}
                  setScaleDown={setScaleDown}
                  setScaleUp={setScaleUp}
                  resizeTop={(width, height) => resizeTop(width, height)}
                  agentDamages={agentDamages}
                  resizeXY={(cagePos) => resizeXY(cagePos)}
                  resizeXYDamage={(i, x, y) => resizeXYDamage(i, x, y)}
                  loadSvg={loadSvg}
                  OnOfSvg={OnOfSvg}
                  addDamages={(damages) => addDamages(damages)}
                  updateDamage={(damages) => updateDamage(damages)}
                  deleteDamage={(damages) => deleteDamage(damages)}
                  updateDamagesAgent={(damages) => updateDamagesAgent(damages)}
                  inspectName={inspectName}
                  setRotate={() => setrotate(rotate + 90)}
                  onZoomChange={(zoom) => onZoomChange(zoom)}
                  calShowCage={calShowCage}
                  onModalImage={onModalImage}
                  offModalImage={offModalImage}
                  onModalImageFull={() => setmodalImageFull(true)}
                  offModalImageFull={() => setmodalImageFull(false)}
                  onModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(true)
                  }
                  offModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(false)
                  }
                  listDamages={listDamages}
                  getDamages={props.getDamages}
                  clearlistDamages={props.clearlistDamages}
                  imgSupport={imgSupport}
                  support={support}
                  dataInspect={dataInspect}
                  setmodalShowTimeOutSession={() =>
                    setmodalShowTimeOutSession(false)
                  }
                />
              )}
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
            style={{ background: '#e9e9ed', borderRadius: '5px' }}
          >
            {/* Inspect Photo */}
            <div className="inspect-photo">
              {/* Damage View */}
              <TitleInspect
                taskSlot={taskSlot}
                taskSettings={taskSetting}
                inspectName={inspectName}
                inspectionInspect={inspectionInspect}
              />
              <InfoBar
                inspectionInspect={inspectionInspect}
                vehicle={vehicle}
                taskInspect={taskInspect}
                taskSlot={taskSlot}
                imgSupport={imgSupport}
                support={support}
                runOneTime={runOneTime}
                setrunOneTime={() => setrunOneTime(true)}
                auth={auth}
                inspectName={inspectName}
                modalShowTimeOutSession={modalShowTimeOutSession}
                setmodalShowTimeOutSession={() =>
                  setmodalShowTimeOutSession(true)
                }
              />
              {showBtn && showBtn.finish && (
                <FinishButton
                  showModalBtn={(title, name, des) =>
                    showModalBtn(title, name, des)
                  }
                />
              )}
              {showInspectInfo && (
                <Inspect
                  getDamages={props.getDamages}
                  getlistTrims={props.getlistTrims}
                  dataInspect={dataInspect}
                  updateDataInspect={setdataInspect}
                  inspectionInspect={inspectionInspect}
                  vehicle={vehicle}
                  taskSlot={taskSlot}
                  taskInspect={taskInspect}
                  getDamagesTire={props.getDamagesTire}
                  sessionId={sessionId}
                  listDamages={listDamages}
                  updateDamage={(damages) => updateDamage(damages)}
                  damages={damages}
                />
              )}
              <div className="checkAcp">
                {showBtn && showBtn.accept && (
                  <AcceptButton
                    taskSettings={taskSetting}
                    showModalBtn={(title, name, des) =>
                      showModalBtn(title, name, des)
                    }
                  />
                )}

                {waitting ? <ModalWatting /> : null}
                {modalImage ? <ModalImage support={support} /> : null}
                {modalImageFull ? (
                  <ModalImageFull
                    src={imgSupport}
                    photoCode={taskSlot.photoCode}
                    inspectName={inspectName}
                  />
                ) : null}
                {modalErr ? (
                  <ModalShowErr resetError={() => resetError()} />
                ) : null}
                {show ? <ModalCheckAdmin /> : null}
                {showConfirm ? (
                  <Modal
                    clickFinish={() => finishImage()}
                    clickAccept={() => acceptImage()}
                    clickReject={() => rejectImage()}
                    title={btnConfirm.title}
                    name={btnConfirm.name}
                    description={btnConfirm.des}
                    clickNo={() => setshowConfirm(false)}
                  />
                ) : null}
                {showWarning ? (
                  <ModalShowErr
                    messErr={messWarning}
                    resetError={() => setshowWarning(false)}
                  />
                ) : null}
                {modalCheckSameInspection ? (
                  <ModalCheckSameInspection
                    task={sameTask}
                    taskSlot={sametaskSlot}
                    vehicle={vehicle}
                    hideModal={() => setmodalCheckSameInspection(false)}
                  />
                ) : null}
                <ModalMD
                  show={modalShowTimeOutSession}
                  title="Time Out"
                  body={
                    <div className="main-content">
                      <div className="form-group">
                        <div className="text-center">
                          <SVG
                            src={'/img/svg/oops.svg'}
                            uniquifyIDs={false}
                          ></SVG>
                          <span
                            className="text-head"
                            style={{ fontSize: '20px' }}
                          >
                            Plz Finish Hurry !!! If you don't want to miss Data
                          </span>
                        </div>
                      </div>
                      <div className="btn-group float-right">
                        <button
                          className="btn btn-warning"
                          onClick={() => finishImage()}
                        >
                          <span
                            className="text-bold"
                            style={{ fontSize: '14px' }}
                          >
                            Yes
                          </span>
                        </button>
                      </div>
                    </div>
                  }
                  handleClose={() => setmodalShowTimeOutSession(false)}
                  sizeModal="modal-md"
                />
              </div>
              {!['qc_tire', 'tire', 'interior', 'qc_interior'].includes(
                inspectName,
              ) ? (
                <DamageView
                  inspectionInspect={inspectionInspect}
                  showSvg={showSvg}
                  svg={cageSvg}
                  setshowSvg={() => setshowSvg(false)}
                  agentDamages={agentDamages}
                  vehicle={vehicle}
                  addDamages={(damages) => addDamages(damages)}
                  updateDamage={(damages) => updateDamage(damages)}
                  deleteDamage={(damages) => deleteDamage(damages)}
                  updateDamagesAgent={(damages) => updateDamagesAgent(damages)}
                  damages={damages}
                  taskSlot={taskSlot}
                  taskInspect={taskInspect}
                  listDamages={listDamages}
                  getDamages={props.getDamages}
                  inspectName={inspectName}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  inspection: state.inspect,
});
export default connect(mapStateToProps, {
  statusStart,
  statusWaitting,
  adminFinish,
  logout,
  timeOutTask,
  getAdminTask,
  resetAll,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  acceptImageRequest,
  rejectImageRequest,
  clearMessageTask,
  getlistTrims,
})(Process);
