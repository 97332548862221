import React from 'react';
import PropTypes from 'prop-types';
import { FileModal } from '../../../cages/containers/widgets';

const InspectItem = (
  key,
  label,
  value,
  disabled = false,
  tags,
  cropImage,
  numberDamage,
  unit_measure,
  grade_score,
  material,
  estimate_included,
  verifyDamage,
  status,
) => {
  let checkFloat = false;
  if (
    [
      'Vin',
      'Make',
      'Model',
      'Year',
      'Body Type',
      'Interior',
      'Interior Status',
      'Transmission',
      'Plate Number',
      'Odometer',
      'Odometer Type',
      'Exterior',
      'Trim',
      'Wheel Front Left',
      'Wheel Front Right',
      'Wheel Rear Left',
      'Wheel Rear Right',
    ].includes(label ? label : '')
  ) {
    checkFloat = true;
  }
  return (
    <div
      key={key}
      className={`row mb-1 ${disabled ? 'text-logan text-strike' : ''} ${
        status === 'repaired' ? 'text-strike text-logan' : ''
      } ${verifyDamage === 'remove' ? 'text-logan' : ''}`}
    >
      <div className="col-4 text-sm" style={{ textTransform: 'uppercase' }}>
        {label}
      </div>
      <div className="col-8 text-sm">
        {unit_measure ? unit_measure : null}
        {material ? (
          <div
            style={{ color: '#fff' }}
            className="badge badge-primary text-head text-uppercase ml-1"
          >
            {material === 'Painted' ? (
              <i className="fas fa-fill-drip"></i>
            ) : material === 'Textured Surface' ? (
              <i className="fas fa-recycle"></i>
            ) : material === 'Chrome' ? (
              <i className="far fa-gem"></i>
            ) : material === 'Alloy Wheel' ? (
              <i className="fas fa-drum-steelpan"></i>
            ) : material === 'Plastic Cover' ? (
              <i className="fas fa-record-vinyl"></i>
            ) : material === 'Steel Wheel' ? (
              <i className="fas fa-dumbbell"></i>
            ) : material === 'Glass' ? (
              <i className="fas fa-glass-whiskey"></i>
            ) : material === 'Steel' ? (
              <i className="fas fa-dharmachakra"></i>
            ) : null}
          </div>
        ) : null}
        {grade_score ? grade_score : null}
        <b className="ml-1" style={{ float: checkFloat ? 'right' : '' }}>
          {value}
        </b>
        {cropImage ? (
          <span className="crop-image ml-1">
            <button
              key={`Crop_${key}_modal_button`}
              className="btn btn-tiny btn-light"
              data-toggle="modal"
              data-target={`#Crop_${key}_modal`}
            >
              <i className="fa fa-image " style={{ color: 'green' }} />
            </button>
            <FileModal
              key={`Crop_${key}_modal`}
              id={`Crop_${key}`}
              title={`${value}`}
              src={cropImage}
              size="modal-lg"
            />
          </span>
        ) : null}
        {status ? (
          <div
            className={`badge text-head badge-${
              status === 'repaired' ? 'warning' : 'primary'
            } ml-1`}
          >
            {status}
          </div>
        ) : null}
        {estimate_included ? estimate_included : null}
        <div className="badge badge-dark text-head" style={{ float: 'right' }}>
          {numberDamage ? `${numberDamage}` : null}
        </div>
        {tags}

        {verifyDamage === 'remove' ? (
          <div style={{ float: 'right' }} className="badge badge-danger mr-1">
            Remove
          </div>
        ) : null}
      </div>
    </div>
  );
};

const findFromList = (list, key, value) => {
  if (list && list.length) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item[key] && item[key].id === parseInt(value, 10)) {
        return item[key];
      }
    }
  }
  return false;
};

const InspectData = ({ data, vehicle, tableDamage }) => {
  let inspect = [];
  if (data.statusImg === 'BAD') {
    let message = Object.keys(data.message || {});
    if (message.length > 0) {
      message = data.message[message[0]];
    }
    inspect.push(InspectItem(`REPORT_${data._id}`, 'REPORT', message));
  }
  let combinedData;
  const taskSlots = data.taskSlots ? data.taskSlots : [];
  if (
    taskSlots &&
    taskSlots.length > 0 &&
    ['PROCESSING', 'INSPECTING', 'ADMIN-INSPECTING', 'ACCEPTED'].includes(
      data.status,
    )
  ) {
    const raw_inspects = taskSlots
      .filter(
        (t) =>
          t.inspectData &&
          typeof t.inspectData == 'object' &&
          Object.keys(t.inspectData).length > 0,
      )
      .map((t) => t.inspectData);

    const detected_inspects = Object.assign({}, ...raw_inspects);

    const raw_damages = taskSlots
      .filter(
        (t) =>
          t &&
          t.inspectData &&
          t.inspectData.detected_damages &&
          t.inspectData.detected_damages.length > 0,
      )
      .map((t) => t.inspectData.detected_damages);

    let detected_damages = [].concat.apply([], raw_damages);

    detected_damages = detected_damages.length > 0 ? detected_damages : [];

    combinedData = { ...detected_inspects, detected_damages };
  }
  let inspectData =
    data.status === 'FINISHED' ? data.inspectData : combinedData;
  const vehData = vehicle && vehicle.data ? vehicle.data : {};
  if (inspectData && inspectData.vin) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_vin`,
        'Vin',
        inspectData.vin.toUpperCase(),
      ),
    );
  }
  if (inspectData && inspectData.make) {
    inspect.push(
      InspectItem(`Finished_${data._id}_make`, 'Make', inspectData.make),
    );
  }
  if (inspectData && inspectData.model) {
    inspect.push(
      InspectItem(`Finished_${data._id}_model`, 'Model', inspectData.model),
    );
  }

  if (inspectData && inspectData.year) {
    inspect.push(
      InspectItem(`Finished_${data._id}_year`, 'Year', inspectData.year),
    );
  }
  if (inspectData && inspectData.body_type) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_body_type`,
        'Body Type',
        inspectData.body_type,
      ),
    );
  }
  if (inspectData && inspectData.trim_id) {
    const vehicleTrimPack = vehData.vehicle_trims_pack || [];
    const checkSource =
      vehicleTrimPack &&
      vehicleTrimPack.length &&
      vehicleTrimPack.some((item) => item.trim && item.trim.source);
    const trims = checkSource ? vehicleTrimPack : vehData.vehicle_trims;
    const trim = findFromList(trims, 'trim', inspectData.trim_id);
    inspect.push(
      InspectItem(
        `Finished_${data._id}_trim`,
        'Trim',
        trim
          ? `${trim.name ? trim.name : ''}
						${trim.drivetrain && !checkSource ? trim.drivetrain : ''}
						${trim.transmission ? trim.transmission : ''}
						${
              trim.transmission_type_id
                ? trim.transmission_type_id && trim.transmission_type_id === 1
                  ? 'Auto'
                  : 'Manual'
                : ' '
            }
						${trim.engine ? trim.engine : ''}
						${trim.engine_type ? trim.engine_type : ''}
						${trim.fuel_type && !checkSource ? trim.fuel_type : ''} 
						${trim.mfr_model_code ? trim.mfr_model_code : ''}
						${trim.brand && !checkSource ? trim.brand : ''}
						${trim.model && !checkSource ? trim.model : ''}
						${trim.year && !checkSource ? trim.year : ''}`
          : inspectData.trim_name
          ? inspectData.trim_name
          : inspectData.trim_id,
      ),
    );
  }
  if (inspectData && inspectData.int_id) {
    const intColors = vehData.vehicle_int_colors || [];
    const intColor = findFromList(intColors, 'int_color', inspectData.int_id);
    inspect.push(
      InspectItem(
        `Finished_${data._id}_int`,
        'Interior',
        intColor
          ? `${intColor.color_name} (${intColor.color_code})`
          : 'undefined',
      ),
    );
  }
  if (inspectData && inspectData.int_status) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_int_status`,
        'Interior Status',
        inspectData.int_status,
      ),
    );
  }
  if (inspectData && inspectData.trans_agent) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_transmission`,
        'Transmission',
        inspectData.trans_agent,
      ),
    );
  }
  if (inspectData && inspectData.odom_reading) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_odom_reading`,
        'Odometer',
        inspectData.odom_reading,
      ),
    );
  }
  if (inspectData && inspectData.authentic) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_authentic`,
        inspectData.authentic,
        inspectData[`${inspectData.authentic}`] ? 'Yes' : 'No',
      ),
    );
  }
  if (inspectData && inspectData.verified) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_verified`,
        inspectData.verified,
        inspectData[`${inspectData.verified}`] ? 'Yes' : 'No',
      ),
    );
  }
  if (inspectData && inspectData.odom_unit) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_odom_unit`,
        'Odometer Type',
        inspectData.odom_unit,
      ),
    );
  }
  if (inspectData && inspectData.tire_status && tableDamage !== 'bmw') {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_tire_status`,
        'Tire Status',
        inspectData.tire_status,
      ),
    );
  }
  if (inspectData && inspectData.ext_id) {
    const extColors = vehData.vehicle_ext_colors || [];
    const extColor = findFromList(extColors, 'ext_color', inspectData.ext_id);
    inspect.push(
      InspectItem(
        `Finished_${data._id}_ext`,
        'Exterior',
        extColor
          ? `${extColor.color_name} (${extColor.color_code})`
          : 'undefined',
      ),
    );
  }
  if (inspectData && inspectData.wheel_front_left) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_wheelFrontLeft`,
        'Wheel Front Left',
        inspectData.wheel_front_left,
      ),
    );
  }
  if (inspectData && inspectData.wheel_front_right) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_wheelFrontRight`,
        'Wheel Front Right',
        inspectData.wheel_front_right,
      ),
    );
  }
  if (inspectData && inspectData.wheel_rear_left) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_wheelRearLeft`,
        'Wheel Rear Left',
        inspectData.wheel_rear_left,
      ),
    );
  }
  if (inspectData && inspectData.wheel_rear_right) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_wheelRearRight`,
        'Wheel Rear Right',
        inspectData.wheel_rear_right,
      ),
    );
  }
  if (inspectData && (inspectData.plate_number || inspectData.vrm)) {
    inspect.push(
      InspectItem(
        `Finished_${data._id}_plate_number`,
        'Plate Number',
        inspectData.plate_number || inspectData.vrm,
      ),
    );
  }

  if (
    [
      '02',
      '04',
      '05',
      '07',
      '08',
      '06',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
    ].includes(data.photoCode)
  ) {
    const damages =
      inspectData && inspectData.detected_damages
        ? inspectData.detected_damages
        : [];
    if (Array.isArray(damages) && damages.length > 0) {
      const damageAccept = damages.filter(
        (item) =>
          item &&
          item.user_response !== 'reject' &&
          item.admin_response !== 'reject' &&
          item.verifyDamage !== 'remove',
      );
      const damageReject = damages.filter(
        (item) =>
          item &&
          (item.user_response === 'reject' || item.admin_response === 'reject'),
      );
      const damageVerify = damages.filter(
        (item) =>
          item &&
          item.verifyDamage === 'remove' &&
          item.user_response !== 'reject' &&
          item.admin_response !== 'reject',
      );

      if (Array.isArray(damageAccept) && damageAccept.length > 0) {
        for (let i = 0; i < damageAccept.length; i++) {
          const damage = damageAccept[i];
          inspect.push(
            InspectItem(
              `Finished_${data._id}_damageAccept_${i}`,
              `(${damage.damage_group}) ${damage.component}`,
              damage.damage_name,
              damage.user_response === 'reject' ||
                damage.admin_response === 'reject',
              damage.source === 'user' ? (
                <span className="badge float-right badge-primary mr-1">
                  user
                </span>
              ) : damage.username ? (
                <span className="badge float-right badge-primary mr-1">
                  {damage.username}
                </span>
              ) : null,
              damage.crop_photo,
              damage.damage_id || i + 1,
              damage.unit_measure ? (
                <span className="badge badge-primary text-head">
                  {damage.unit_measure.includes('Component') ||
                  damage.unit_measure.includes('component')
                    ? 'C'
                    : 'I'}
                </span>
              ) : null,
              damage.grade_score ? (
                <span
                  style={{
                    color: damage.grade_score === 0 ? '#fff' : '#000',
                    background:
                      damage.grade_score === 0
                        ? 'grey'
                        : damage.grade_score === 1
                        ? 'yellow'
                        : damage.grade_score === 2
                        ? 'orange'
                        : damage.grade_score >= 3
                        ? 'red'
                        : '',
                  }}
                  className="badge badge-primary text-head ml-1"
                >
                  {damage.grade_score}
                </span>
              ) : null,
              damage.material || false,
              damage.estimate_included ? (
                <span
                  className="badge badge-warning ml-1"
                  style={{ fontWeight: 'bold' }}
                >
                  <span> Included</span>
                </span>
              ) : null,
              false,
              damage.status,
            ),
          );
        }
      }
      if (Array.isArray(damageVerify) && damageVerify.length > 0) {
        for (let i = 0; i < damageVerify.length; i++) {
          const damage = damageVerify[i];

          inspect.push(
            InspectItem(
              `Finished_${data._id}_damageVerify_${i}`,
              `(${damage.damage_group}) ${damage.component}`,
              damage.damage_name,
              false,
              damage.user_response === 'reject' ? (
                <span className="badge float-right badge-danger mr-1">
                  user
                </span>
              ) : damage.username ? (
                <span className="badge float-right badge-danger mr-1">
                  {damage.username}
                </span>
              ) : null,

              damage.crop_photo,
              false,
              damage.unit_measure ? (
                <span className="badge badge-primary text-head">
                  {damage.unit_measure.includes('Component') ||
                  damage.unit_measure.includes('component')
                    ? 'C'
                    : 'I'}
                </span>
              ) : null,
              damage.grade_score ? (
                <span
                  style={{
                    color: damage.grade_score === 0 ? '#fff' : '#000',
                    background:
                      damage.grade_score === 0
                        ? 'grey'
                        : damage.grade_score === 1
                        ? 'yellow'
                        : damage.grade_score === 2
                        ? 'orange'
                        : damage.grade_score >= 3
                        ? 'red'
                        : '',
                  }}
                  className="badge badge-primary text-head ml-1"
                >
                  {damage.grade_score}
                </span>
              ) : null,
              damage.material || false,
              false,
              damage.verifyDamage,
            ),
          );
        }
      }
      if (Array.isArray(damageReject) && damageReject.length > 0) {
        for (let i = 0; i < damageReject.length; i++) {
          const damage = damageReject[i];
          inspect.push(
            InspectItem(
              `Finished_${data._id}_damageReject_${i}`,
              `(${damage.damage_group}) ${damage.component}`,
              damage.damage_name,
              damage.user_response === 'reject' ||
                damage.admin_response === 'reject',
              damage.user_response === 'reject' ? (
                <span className="badge float-right badge-danger mr-1">
                  user
                </span>
              ) : damage.username ? (
                <span className="badge float-right badge-danger mr-1">
                  {damage.username}
                </span>
              ) : null,
              damage.crop_photo,
              false,
              damage.unit_measure ? (
                <span className="badge badge-primary text-head">
                  {damage.unit_measure.includes('Component') ||
                  damage.unit_measure.includes('component')
                    ? 'C'
                    : 'I'}
                </span>
              ) : null,
              damage.grade_score ? (
                <span
                  style={{
                    color: damage.grade_score === 0 ? '#fff' : '#000',
                    background:
                      damage.grade_score === 0
                        ? 'grey'
                        : damage.grade_score === 1
                        ? 'yellow'
                        : damage.grade_score === 2
                        ? 'orange'
                        : damage.grade_score >= 3
                        ? 'red'
                        : '',
                  }}
                  className="badge badge-primary text-head ml-1"
                >
                  {damage.grade_score}
                </span>
              ) : null,
              damage.material || false,
            ),
          );
        }
      }
    }
  }

  return <div className="inspect-data">{inspect}</div>;
};

InspectData.propTypes = {
  data: PropTypes.object,
};

export default InspectData;
