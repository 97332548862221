import actions from './actions';
import * as client from '../../utils/client';
import * as typesAuth from './../auth/types';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
// let vehicleApi =
// 	process.env.REACT_APP_PAVE_VEHICLE || 'https://vehicles-dev.paveapi.com';
// let tokenVehicle = {
// 	Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTcxNjU2OTUsImlkIjo3MX0.ruMuF9EyWaCJ5PMjHzVis3RV0qABeJmB95ZC69uxOOo`,
// };

export const getInspectionsLeft = (params, loading = true) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/inspections/admin`,
      params,
      function (res) {
        dispatch(actions.getInspectionsLeft(res));
      },
      loading,
    );
};
export const getInspectionsRight = (params, loading = true) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/inspections/admin`,
      params,
      function (res) {
        dispatch(actions.getInspectionsRight(res));
      },
      loading,
    );
};
export const getInspectionsRd = (params, loading = true) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/inspections/admin`,
      params,
      function (res) {
        dispatch(actions.getInspectionsRd(res));
      },
      loading,
    );
};
export const getInspection = (id, loading = true) => {
  // if (process.env.NODE_ENV === "development") {
  //     const sampleInspectionDetail = require("./samples/inspectionDetail.json");
  //     return dispatch => dispatch(actions.getInspection(sampleInspectionDetail));
  // }
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/inspections/admin/${id}`,
      {},
      function (res) {
        dispatch(actions.getInspection(res));
      },
      loading,
    );
};
export const clearInspection = () => {
  return (dispatch) => dispatch(actions.clearInspection());
};
export const clearSuccess = () => {
  return (dispatch) => dispatch(actions.clearSuccess());
};
export const completeInspection = (id, loading = true) => {
  // if (process.env.NODE_ENV === "development") {
  //     const sampleInspectionDetail = require("./samples/inspectionDetail.json");
  //     return dispatch => dispatch(actions.getInspection(sampleInspectionDetail));
  // }
  return (dispatch) =>
    client.exec(
      'post',
      `${agentAPI}/api/inspections/admin/complete`,
      { inspectionID: id },
      function (res) {
        dispatch(actions.completeInspection(res));
      },
      loading,
    );
};
export const changeStatusInspection = (data, loading = true) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/change-stt-inspection`,
      data,
      function (res) {
        dispatch(actions.changeStatusInspection(res));
      },
      loading,
    );
};
export const inspectAllTask = (sessionID, loading = true) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/inspectAllTask/${sessionID}`,
      {},
      function (res) {
        dispatch(actions.inspectAll(res));
      },
      loading,
    );
};
export const updateVehicleApi = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/admin/adminEditVehicle`,
      data,
      function (res) {
        dispatch(actions.updateVehicleApi(res));
      },
      true,
    );
};
export const rejectTask = (id, data, loading = true) => {
  data['from'] = 'ADMIN';
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/tasks/${id}/reject`,
      data,
      function (res) {
        dispatch(actions.rejectTask(res));
      },
      loading,
    );
};
export const reportImage = (id, data, loading = true) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/tasks/${id}/report`,
      data,
      function (res) {
        dispatch(actions.reportImage(res));
      },
      loading,
    );
};
export const kickAgent = (id, loading = true) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/tasks/${id}/kickAgent`,
      function (res) {
        dispatch(actions.kickAgent(res));
      },
      loading,
    );
};
export const acceptTask = (id, data, loading = true) => {
  // if (process.env.NODE_ENV === "development") {
  //     const sampleInspectionDetail = require("./samples/inspectionDetail.json");
  //     return dispatch => dispatch(actions.getInspection(sampleInspectionDetail));
  // }
  data['from'] = 'ADMIN';
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/tasks/${id}/accept`,
      data,
      function (res) {
        dispatch(actions.acceptTask(res));
      },
      loading,
    );
};
export const getActiveCages = (params) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/cages/active`,
      params,
      function (res) {
        dispatch(actions.getActiveCages(res));
      },
      false,
    );
};
export const getAdminAction = (params) => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/users/history`, params, function (res) {
      dispatch(actions.getAdminAction(res));
    });
};
export const putInspectionCage = (id, cageId) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/admin/${id}`,
      { cageId },
      function (res) {
        dispatch(actions.putInspectionCage(res));
      },
    );
};
export const inspectInspection = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/inspect`,
      data,
      function (res) {
        dispatch(actions.inspectInspection(res));
        dispatch({
          type: typesAuth.UPDATE_TASKFAILED,
          payload:
            res && res.totalTaskFailed
              ? { totalTaskFailed: res.totalTaskFailed }
              : { totalTaskFailed: 0 },
        });
      },
      true,
    );
};
export const checkInspection = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/check`,
      data,
      function (res) {
        dispatch(actions.checkInspection(res));
      },
      true,
    );
};
export const manage = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/manage`,
      data,
      function (res) {
        dispatch(actions.manage(res));
      },
      true,
    );
};
export const holding = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/holding`,
      data,
      function (res) {
        dispatch(actions.holding(res));
      },
      true,
    );
};
export const putSkipVin = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/skipVin`,
      data,
      function (res) {
        dispatch(actions.putSkipVin(res));
      },
      true,
    );
};
export const qcPassed = (sessionID) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/qcPassed/${sessionID}`,
      {},
      function (res) {
        dispatch(actions.qcPassed(res));
      },
      true,
    );
};
export const invalid = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/invalid`,
      data,
      function (res) {
        dispatch(actions.invalid(res));
      },
      true,
    );
};
export const updateAction = (data) => {
  return () => {
    client.exec(
      'put',
      `${agentAPI}/api/users/updateAction`,
      data,
      function (res) {
        return res;
      },
      false,
    );
  };
};
export const validatePhoto = (sessionID, data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/${sessionID}/validatePhoto`,
      data,
      function (res) {
        dispatch(actions.validatePhoto(res));
      },
      true,
    );
};
export const getSessionRd = (role, actionValidate) => {
  let actionApi = actionValidate ? 'validateRd' : 'checkRd';
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/inspections/${
        role === 'AGENT' ? 'inspectRd' : role === 'QA' ? actionApi : ''
      }`,
      {},
      function (res) {
        dispatch(actions.getSessionRd(res));
      },
      false,
    );
};
export const adminSetStandard = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/inspections/setStandard`,
      data,
      function (res) {
        dispatch(actions.adminSetStandard(res));
      },
      true,
    );
};
export const TaskSlotStatus = (taskId, data) => {
  return (dispatch) =>
    client.exec(
      'post',
      `${agentAPI}/api/tasks/${taskId}/changeStatus`,
      data,
      function (res) {
        dispatch(actions.TaskSlotStatus(res));
      },
      true,
    );
};
export const getProcessPhoto = (taskId) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/tasks/admin/${taskId}/process-photo`,
      {},
      function (res) {
        dispatch(actions.getProcessPhoto(res));
      },
      true,
    );
};
