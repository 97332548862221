import actions from './actions';
import axios from 'axios';
import * as types from './types';

let agentAPI =
	process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
let damagesApi =
	process.env.REACT_APP_DAMAGE_BASE_URL || 'https://damages.paveapi.com';
let vehicleApi =
	process.env.REACT_APP_VEHICLE_ENDPOINT || 'https://vehicles.paveapi.com';
export const getMission = (idTaskSlot) => {
	return (dispatch) => {
		if (idTaskSlot) {
			axios
				.get(`${agentAPI}/api/tasks/homework`, {
					params: {
						idTaskSlot: idTaskSlot,
					},
				})
				.then((res) => {
					dispatch(actions.getMissionDone(res.data));
				})
				.catch((e) => dispatch(actions.hasError(e)));
		} else {
			axios
				.get('/api/tasks/homework')
				.then((res) => {
					dispatch(actions.getMissionDone(res.data));
				})
				.catch((e) => dispatch(actions.hasError(e)));
		}
	};
};
export const getDamages = (item, tableDamage) => {
	return (dispatch) => {
		if (tableDamage === 'bmw') {
			axios
				.get(`${damagesApi}/api/bmw-damages`, {
					params: {
						...item,
					},
				})
				.then((res) => {
					dispatch(actions.getDamagesDone(res.data));
				})
				.catch((e) => dispatch(actions.hasError(e)));
		} else if (tableDamage === 'autograde') {
			axios
				.get(`${damagesApi}/api/damages`, {
					params: {
						...item,
					},
				})
				.then((res) => {
					dispatch(actions.getDamagesDone(res.data));
				})
				.catch((e) => dispatch(actions.hasError(e)));
		} else {
			axios
				.get(`${damagesApi}/api/pave-damages`, {
					params: {
						...item,
					},
				})
				.then((res) => {
					dispatch(actions.getDamagesDone(res.data));
				})
				.catch((e) => dispatch(actions.hasError(e)));
		}
	};
};
export const getDamagesTire = (id) => {
	return (dispatch) => {
		axios
			.get(`${damagesApi}/api/tires/${id}`)
			.then((res) => {
				dispatch(actions.getDamagesDone(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const getlistTrims = (sessionID) => {
	return (dispatch) => {
		axios
			.get(`${vehicleApi}/api/vehicle/trims/${sessionID}`, {
				headers: {
					Authorization:
						'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTcxNjU2OTUsImlkIjo3MX0.ruMuF9EyWaCJ5PMjHzVis3RV0qABeJmB95ZC69uxOOo',
				},
			})
			.then((res) => {
				dispatch(actions.getDamagesDone(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const clearlistDamages = () => (dispatch) => {
	dispatch(actions.clearListDamagesDone());
};
export const sumaryTask = () => {
	return (dispatch) => {
		axios
			.get(`${agentAPI}/api/users/agents/stats`)
			.then((res) => {
				dispatch(actions.sumaryTaskUser(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const getAdminTask = (id) => {
	return (dispatch) => {
		axios
			.get(`${agentAPI}/api/tasks/admin/${id}`)
			.then((res) => {
				dispatch(actions.getAdminTaskDone(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const adminFinish = (data, id) => {
	return (dispatch) => {
		axios
			.put(`${agentAPI}/api/tasks/admin/${id}`, data)
			.then((res) => {
				dispatch(actions.adminFinishDone(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const clearMessageTask = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_MESSAGE,
	});
};
export const rejectImageRequest = (data, id) => {
	return (dispatch) => {
		axios
			.put(`${agentAPI}/api/tasks/${id}/reject`, data)
			.then((res) => {
				dispatch(actions.rejectImage(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const resetAll = () => {
	return (dispatch) => {
		dispatch(actions.resetInspection());
	};
};
export const acceptImageRequest = (data, id) => {
	return (dispatch) => {
		axios
			.put(`${agentAPI}/api/tasks/${id}/accept`, data)
			.then((res) => {
				dispatch(actions.acceptImage(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const finishImageRequest = (data, id) => {
	return (dispatch) => {
		axios
			.put(`${agentAPI}/api/tasks/${id}/finish`, data)
			.then((res) => {
				dispatch(actions.finishImage(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const timeOutTask = (id, data) => {
	return (dispatch) => {
		axios
			.put(`${agentAPI}/api/tasks/${id}/timeout`, data)
			.then((res) => {
				dispatch(actions.timeOut(res.data));
			})
			.catch((e) => dispatch(actions.hasError(e)));
	};
};
export const statusWaitting = () => (dispatch) => {
	dispatch({
		type: types.STATUS_WAIT,
	});
};

export const statusStart = () => (dispatch) => {
	dispatch({
		type: types.STATUS_START,
	});
};
export const statusProcess = () => (dispatch) => {
	dispatch({
		type: types.STATUS_PROCESS,
	});
};
export const countTime = () => (dispatch) => {
	dispatch({
		type: types.COUNT_TIME,
	});
};
export const countTimeTask = () => (dispatch) => {
	dispatch({
		type: types.COUNT_TIMETASK,
	});
};
export const setTimeCount = (time) => (dispatch) => {
	dispatch(actions.setTime(time));
};
